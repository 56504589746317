import { default as index0ffegz2vpjMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/404/index.vue?macro=true";
import { default as indexR3xAZAkeKqMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/admin/index.vue?macro=true";
import { default as indexjSknToo52wMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/index.vue?macro=true";
import { default as indexPuzbpY4jRTMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/timeline/index.vue?macro=true";
import { default as _91id_93jhoN2NLrrrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id].vue?macro=true";
import { default as indexv6H2bugwoGMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/bounced/index.vue?macro=true";
import { default as indexAGpwYeOwSPMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/index.vue?macro=true";
import { default as indextmxYjpa6WVMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/markedspam/index.vue?macro=true";
import { default as indexSBmf98pQEhMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/unsubscribed/index.vue?macro=true";
import { default as tablenkdHMUlYUSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table.vue?macro=true";
import { default as indexJawLiEgtn9Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id]/index.vue?macro=true";
import { default as _91id_93tM6NJq5ps4Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id].vue?macro=true";
import { default as index3evSX2wLBiMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields/index.vue?macro=true";
import { default as match_45fieldsCQ9OxOMFjfMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields.vue?macro=true";
import { default as indexdi21XjllGhMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file/index.vue?macro=true";
import { default as select_45fileYL30Wcd7CpMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file.vue?macro=true";
import { default as _91id_93sWFSgstMYeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id].vue?macro=true";
import { default as newRzl3phtPXdMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new.vue?macro=true";
import { default as indexO1jlh8wtmwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table/index.vue?macro=true";
import { default as tableMpXtWhOxkmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table.vue?macro=true";
import { default as index3ZCtaxs5u0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/edit/index.vue?macro=true";
import { default as indexj9g5L7YHl6Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/index.vue?macro=true";
import { default as indexnSmuNYPx7LMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/new/index.vue?macro=true";
import { default as indexWnQN7wmnrrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table/index.vue?macro=true";
import { default as tableasoI3vWX7cMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table.vue?macro=true";
import { default as indexBrnjqOPM7JMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id]/index.vue?macro=true";
import { default as _91id_933zTNYzdrZ7Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id].vue?macro=true";
import { default as indexNoCdVhavw5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields/index.vue?macro=true";
import { default as match_45fieldstOrfTMRquhMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields.vue?macro=true";
import { default as indexBOBIGVBoGkMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file/index.vue?macro=true";
import { default as select_45fileTdo07y7InxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file.vue?macro=true";
import { default as _91id_93ZwU32KOdsnMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id].vue?macro=true";
import { default as newnxPbmpiIAeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new.vue?macro=true";
import { default as indexMkhGcCxqu5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table/index.vue?macro=true";
import { default as table9Ngr8623HJMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table.vue?macro=true";
import { default as indexF2anYtxHsHMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/configure/index.vue?macro=true";
import { default as indexkc1q7qXw0GMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/edit/index.vue?macro=true";
import { default as indexG8AC7KMJ2bMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/name/index.vue?macro=true";
import { default as _91id_9398HhicJj5EMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id].vue?macro=true";
import { default as indexPrLJQuxhSEMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/info/[id]/index.vue?macro=true";
import { default as indexeUznPR7zc2Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/configure/index.vue?macro=true";
import { default as indexOIdgYPfFL6Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/create/index.vue?macro=true";
import { default as indexZ0oNcutsZSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/name/index.vue?macro=true";
import { default as new5ipGxHlT8xMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new.vue?macro=true";
import { default as tableDzisSdk2xzMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/table.vue?macro=true";
import { default as indexXtipGTxNDSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/bulk-action/table/index.vue?macro=true";
import { default as edit1VoANrvVBxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/edit.vue?macro=true";
import { default as indexxGvy5rUaIsMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/index.vue?macro=true";
import { default as indexNfFULWp8sPMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/performance/index.vue?macro=true";
import { default as overviewcij3A4nRgkMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview.vue?macro=true";
import { default as goalOqtCMzrIsqMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/goal.vue?macro=true";
import { default as reportDvO0aCKS10Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/report.vue?macro=true";
import { default as timelineIc1blVBGg3Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/timeline.vue?macro=true";
import { default as _91id_93B1x9ANOq3TMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id].vue?macro=true";
import { default as indexYqn93TeIdZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/edit/index.vue?macro=true";
import { default as indexJV94HMnxKxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/goal/index.vue?macro=true";
import { default as indexaN6dGABhqmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/template/index.vue?macro=true";
import { default as indexbvQsdm9karMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/timeline/index.vue?macro=true";
import { default as new3uwK7ZMf9jMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new.vue?macro=true";
import { default as index823om8D4p9Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/active/index.vue?macro=true";
import { default as indexcCww4shgG7Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/draft/index.vue?macro=true";
import { default as indexjBQVczarmsMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/index.vue?macro=true";
import { default as indexx6U0TjgvAOMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/paused/index.vue?macro=true";
import { default as tableMznWCbNrNyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table.vue?macro=true";
import { default as indexI8CWImfjipMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/index.vue?macro=true";
import { default as indexEuD4t6Jj9DMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/performance/index.vue?macro=true";
import { default as overviewKi5ybeu2GwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview.vue?macro=true";
import { default as indexQ1crSfMJBuMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/index.vue?macro=true";
import { default as index8nmmasNJRyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/index.vue?macro=true";
import { default as indexg4dyp3yao3Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/performance/index.vue?macro=true";
import { default as overviewQJwZg04d8OMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview.vue?macro=true";
import { default as indexyNPpaoOBlqMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/content/index.vue?macro=true";
import { default as indexndA8ijzudmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/design/index.vue?macro=true";
import { default as indexdF1dmVFGokMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/recipient/index.vue?macro=true";
import { default as indexC5Qc5xjPp0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/review/index.vue?macro=true";
import { default as indexTHz5YebS3dMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/schedule/index.vue?macro=true";
import { default as indexxGE4fDcf3mMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93u8iDpR3Ub2Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id].vue?macro=true";
import { default as indexhbXiylsp8WMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/content/index.vue?macro=true";
import { default as indexBaFBxlEjVsMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/design/index.vue?macro=true";
import { default as indexSMVg4YaoRhMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/recipient/index.vue?macro=true";
import { default as index3ElI2UuLvPMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/review/index.vue?macro=true";
import { default as indexz0aH9B674fMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/schedule/index.vue?macro=true";
import { default as index6zbkr6x29hMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/sender/index.vue?macro=true";
import { default as newu2GaXjf1eUMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new.vue?macro=true";
import { default as index1XXLZGoD6JMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/draft/index.vue?macro=true";
import { default as indexD5umTmMjcFMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/finished/index.vue?macro=true";
import { default as indexIJz9qlpjj9Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/index.vue?macro=true";
import { default as indexIFjn6zVbKcMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/scheduled/index.vue?macro=true";
import { default as indexkWv6mrGny8Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/tested/index.vue?macro=true";
import { default as indexoS0sRR7qPZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/testing/index.vue?macro=true";
import { default as indexZzRcc1StHWMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerScheduled/index.vue?macro=true";
import { default as index892ijadtxXMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerSending/index.vue?macro=true";
import { default as tablevrvrbDFmxqMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table.vue?macro=true";
import { default as indexQcc7SM8jU0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/index.vue?macro=true";
import { default as indexGW7VNwmx2YMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/performance/index.vue?macro=true";
import { default as overviewChsl29o2rpMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview.vue?macro=true";
import { default as indexpgXSog0rXNMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/content/index.vue?macro=true";
import { default as indexrLn9QGJV8TMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/design/index.vue?macro=true";
import { default as indexlLvkSPoFrrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/review/index.vue?macro=true";
import { default as indextYmmv7155fMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/sender/index.vue?macro=true";
import { default as indexcnISNFD9mwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/trigger/index.vue?macro=true";
import { default as _91id_93UjAI8FRLnwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id].vue?macro=true";
import { default as indexGsuwlpLGTDMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/content/index.vue?macro=true";
import { default as indexauID4iAFkvMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/design/index.vue?macro=true";
import { default as indexdbkTjHj6zWMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/review/index.vue?macro=true";
import { default as index0t4BBEjWQPMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/sender/index.vue?macro=true";
import { default as index41e71ORNk3Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/trigger/index.vue?macro=true";
import { default as new2pc5sbE1zvMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new.vue?macro=true";
import { default as indexoP4hKcv7JgMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/active/index.vue?macro=true";
import { default as indexjIX9lwslITMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/draft/index.vue?macro=true";
import { default as indexqqiCk4rBhfMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/index.vue?macro=true";
import { default as tablee4Dwl6vKpSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table.vue?macro=true";
import { default as indexPeDUZPVnbrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/index.vue?macro=true";
import { default as indexkKMrBHqc9vMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/index.vue?macro=true";
import { default as indexzMYevtuceIMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/performance/index.vue?macro=true";
import { default as overviewHXaMhczTxuMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview.vue?macro=true";
import { default as indexeeGN1HlUDtMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/content/index.vue?macro=true";
import { default as indexkaYTLuWpgSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93j5A8rtPir2Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id].vue?macro=true";
import { default as indexxFCLWCBmSdMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/content/index.vue?macro=true";
import { default as index7u3S6oYnA0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/sender/index.vue?macro=true";
import { default as newczplICRaMyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new.vue?macro=true";
import { default as indexHGNHD2V9IhMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/active/index.vue?macro=true";
import { default as indexywtRqHd4jeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/index.vue?macro=true";
import { default as indexIKDcXrvfhQMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/paused/index.vue?macro=true";
import { default as table0GNyNSlIKsMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table.vue?macro=true";
import { default as indexAb851awbJWMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/content/index.vue?macro=true";
import { default as index1UQBWpfY47Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/design/index.vue?macro=true";
import { default as indexA6EFJnJGy5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/recipient/index.vue?macro=true";
import { default as indexz7HL0J7bVMMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/review/index.vue?macro=true";
import { default as indexSHuAD2VElGMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/schedule/index.vue?macro=true";
import { default as index3dHpzNJyVFMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/sender/index.vue?macro=true";
import { default as _91id_93oEaLCTH6lbMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id].vue?macro=true";
import { default as indexKfqpBc7zdCMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/content/index.vue?macro=true";
import { default as indexcd6cqCM2pHMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/design/index.vue?macro=true";
import { default as indextojMhdl7GvMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/recipient/index.vue?macro=true";
import { default as indexGof661UY8IMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/review/index.vue?macro=true";
import { default as indexlKw27qNNciMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/schedule/index.vue?macro=true";
import { default as index4hNFOFhyzQMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/sender/index.vue?macro=true";
import { default as newpWqBhZLf8NMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new.vue?macro=true";
import { default as indexk4CfhgwbCqMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/draft/index.vue?macro=true";
import { default as indexah5ajdwTbwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/index.vue?macro=true";
import { default as index225xdsNGNLMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/quarantined/index.vue?macro=true";
import { default as indexnuTL1urV6qMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/scheduled/index.vue?macro=true";
import { default as index6K6lPWlwfpMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sending/index.vue?macro=true";
import { default as indexeF5orLlTtKMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sent/index.vue?macro=true";
import { default as tableQoLpTWL2DxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table.vue?macro=true";
import { default as indexU4LmYqcl0IMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/report/index.vue?macro=true";
import { default as indexpqDrvxwxIjMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/index.vue?macro=true";
import { default as indexhzEhyf3vNUMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/index.vue?macro=true";
import { default as indexROUYvA6fjXMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/performance/index.vue?macro=true";
import { default as overviewNB5asYabdrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview.vue?macro=true";
import { default as indexE6XQAFlXP5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/basic/index.vue?macro=true";
import { default as indexkVKoJxyDLrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/content/index.vue?macro=true";
import { default as indexvXazr30bCOMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/recipient/index.vue?macro=true";
import { default as indexdeqtp5ZPIEMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/schedule/index.vue?macro=true";
import { default as _91id_93Ay6PXBB98TMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id].vue?macro=true";
import { default as indexLiAduCGwbrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/basic/index.vue?macro=true";
import { default as indexWvNfjGd7y0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/content/index.vue?macro=true";
import { default as indexyvJ7xMv7D6Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/recipient/index.vue?macro=true";
import { default as indexQxAZzp2XOrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/schedule/index.vue?macro=true";
import { default as newwXJyM1nAT1Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new.vue?macro=true";
import { default as indexzkkVvwuBT7Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/active/index.vue?macro=true";
import { default as indexlir66only9Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/draft/index.vue?macro=true";
import { default as indexFLlvF8HPniMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/index.vue?macro=true";
import { default as indexN9m1DhMVQIMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/paused/index.vue?macro=true";
import { default as tableKP3ORl0HTtMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table.vue?macro=true";
import { default as indexxn5IOLkEIxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/dashboard/index.vue?macro=true";
import { default as indexXftLBROX4OMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/download/index.vue?macro=true";
import { default as index2ec275eytvMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/error/index.vue?macro=true";
import { default as indexw2HlcI15CIMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/index.vue?macro=true";
import { default as indexhu8Aqg3KZcMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/instructions/index.vue?macro=true";
import { default as index5KOeqsA7HZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/performance/index.vue?macro=true";
import { default as indexnXHsEZnLLgMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/submission/index.vue?macro=true";
import { default as overviewvKU6hiI1vjMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview.vue?macro=true";
import { default as index9vIXdPOj0iMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/basic/index.vue?macro=true";
import { default as indexrnY9GH540wMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/content/index.vue?macro=true";
import { default as index6unOk28NDAMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/design/index.vue?macro=true";
import { default as indexibZAH6UTduMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/success/index.vue?macro=true";
import { default as indexV0ibwN8MgKMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/targeting/index.vue?macro=true";
import { default as _91id_93GD6fEuPC7AMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id].vue?macro=true";
import { default as indexXiSf6HosNJMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/index.vue?macro=true";
import { default as indexnC9gXn7mNVMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/instructions/index.vue?macro=true";
import { default as indexppFi8TJ7o1Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/performance/index.vue?macro=true";
import { default as indexzRhz3RyfHHMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/submission/index.vue?macro=true";
import { default as overviewhoZ7yWU9XRMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview.vue?macro=true";
import { default as index2B6gmeK8jNMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/basic/index.vue?macro=true";
import { default as indexgjc14b0xYfMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/content/index.vue?macro=true";
import { default as indexuO2J4k5j0IMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/design/index.vue?macro=true";
import { default as index6gaqQUguYUMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success-page/index.vue?macro=true";
import { default as indexFJ7T2qBREWMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success/index.vue?macro=true";
import { default as _91id_93EFQAZj5621Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id].vue?macro=true";
import { default as EmbeddedFormIWAvLfULvdMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/EmbeddedForm.vue?macro=true";
import { default as indexrpvQZpWnnpMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/index.vue?macro=true";
import { default as LandingPageduWCRDhmxZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/LandingPage.vue?macro=true";
import { default as PopupaWK6Z8TuB5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/Popup.vue?macro=true";
import { default as index2T8DNan26iMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/behaviour/index.vue?macro=true";
import { default as EmbeddedFormvpVCDFa2YBMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm.vue?macro=true";
import { default as EmbeddedForm2gMC8LVJrDMMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm2.vue?macro=true";
import { default as indexlWAtlg5ZJUMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/index.vue?macro=true";
import { default as LandingPage9AaQdIw5gxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/LandingPage.vue?macro=true";
import { default as PopupCD8HDjUZj8Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup.vue?macro=true";
import { default as Popup2MrAiBeim12Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup2.vue?macro=true";
import { default as EmbeddedFormdor0K0Swx8Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/EmbeddedForm.vue?macro=true";
import { default as indexfMIRAG5s3TMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/index.vue?macro=true";
import { default as LandingPageInZnLue3ZlMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/LandingPage.vue?macro=true";
import { default as PopupnBTHGThsS4Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/Popup.vue?macro=true";
import { default as indexjOfPwFLEHeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success-page/index.vue?macro=true";
import { default as EmbeddedFormtW4Gc318feMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/EmbeddedForm.vue?macro=true";
import { default as indexpoBDkKwMQTMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/index.vue?macro=true";
import { default as LandingPagedSvlUqtCPiMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/LandingPage.vue?macro=true";
import { default as PopuphuKFZ8EXHYMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/Popup.vue?macro=true";
import { default as EmbeddedForm55ufzucMeZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/EmbeddedForm.vue?macro=true";
import { default as indexkj0BxVyxXlMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/index.vue?macro=true";
import { default as PopupuGVXCmEpD7Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/Popup.vue?macro=true";
import { default as indexvQJYFtoDs0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/type/index.vue?macro=true";
import { default as newnoTJy6aMGaMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new.vue?macro=true";
import { default as indexfuTwMWP80HMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/index.vue?macro=true";
import { default as indexAQ9JzFdEbmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/instructions/index.vue?macro=true";
import { default as indexJVI8rTDka8Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/performance/index.vue?macro=true";
import { default as index5eEPSvaVw4Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/submission/index.vue?macro=true";
import { default as overviewBTUeWhiJCrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview.vue?macro=true";
import { default as indexZq2TmwGSusMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/basic/index.vue?macro=true";
import { default as indexzEyTy3XkZpMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/behaviour/index.vue?macro=true";
import { default as indexkB4fxy6iuxMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/content/index.vue?macro=true";
import { default as indexkvuPLbow3DMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/design/index.vue?macro=true";
import { default as indexo3BpUVyxtaMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/success/index.vue?macro=true";
import { default as indexlkWBofXliGMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/targeting/index.vue?macro=true";
import { default as _91id_93CKX0rTTEeHMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id].vue?macro=true";
import { default as indexiGyy3Su4qYMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/embed/index.vue?macro=true";
import { default as index5rrKVdAUxmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/landing-page/index.vue?macro=true";
import { default as indexuadCLRSIunMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/popup/index.vue?macro=true";
import { default as table3uFWvvqAWRMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table.vue?macro=true";
import { default as indexNEUHO9Zk4zMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/index.vue?macro=true";
import { default as indexAoEGmsCgStMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/click-funnels/index.vue?macro=true";
import { default as index0XLexsik4ZMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/convert-box/index.vue?macro=true";
import { default as indexS9vIRcexQlMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/demio/index.vue?macro=true";
import { default as indexRTDQ3TIz7NMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/hubspot/index.vue?macro=true";
import { default as indexN2r0f85x6uMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/instapage/index.vue?macro=true";
import { default as indexou9lk2vLSyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/intercom/index.vue?macro=true";
import { default as indexpeVNlJ4reCMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/paypal/index.vue?macro=true";
import { default as indexDWJQUUkzjNMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/pipedrive/index.vue?macro=true";
import { default as indexBhQq4gz2mTMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/samcart/index.vue?macro=true";
import { default as indextFZfQBzaIrMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/stripe/index.vue?macro=true";
import { default as indexw1dyaOoPDmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/teachable/index.vue?macro=true";
import { default as indexktFBXpAifyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/twilio/index.vue?macro=true";
import { default as indexHB8SvSLQs0Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woo-commerce/index.vue?macro=true";
import { default as indexysR59ENHquMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woorise/index.vue?macro=true";
import { default as indexoiF3bswgWRMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/zapier/index.vue?macro=true";
import { default as indexroNVhdxkDJMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/login/index.vue?macro=true";
import { default as indextSf3AjXJ3TMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/index.vue?macro=true";
import { default as index2xlmDL3UpyMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/member/index.vue?macro=true";
import { default as indexBnslPupYKQMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/profile/index.vue?macro=true";
import { default as indexoOu7hCmGCaMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/register/index.vue?macro=true";
import { default as indexo0crg569oDMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/index.vue?macro=true";
import { default as indexRGonIvKrTKMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/newpassword/index.vue?macro=true";
import { default as indexYcKxRh3mtNMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/billing/index.vue?macro=true";
import { default as index0SJcp37lSVMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/api/index.vue?macro=true";
import { default as indexABEjKule5IMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/webhook/index.vue?macro=true";
import { default as connectorsGO0vOy6xTfMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors.vue?macro=true";
import { default as _91id_933cQuULhnUYMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/[id].vue?macro=true";
import { default as indexvX6vOQd0DSMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/index.vue?macro=true";
import { default as indexXk9ED46CiwMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-fields/index.vue?macro=true";
import { default as custom_45propertiesNFHy4fJjmYMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties.vue?macro=true";
import { default as indexIcKQnqWRSUMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/home-page/index.vue?macro=true";
import { default as indexQoMXrGEv5AMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/add-ons/index.vue?macro=true";
import { default as indexgReD911T6pMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/integrations/index.vue?macro=true";
import { default as indexC7tJ0sK6CKMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/partners/index.vue?macro=true";
import { default as marketplace3lziaxw8ixMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace.vue?macro=true";
import { default as indexfM2FsHEuIsMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/company-information/index.vue?macro=true";
import { default as indexA5nULu21GCMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/domain-whitelisting/index.vue?macro=true";
import { default as indexeTQpMoTQMOMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/editor/index.vue?macro=true";
import { default as index8a5Jk6lbkfMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/sender/index.vue?macro=true";
import { default as index0RXe2HjZZLMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/template/index.vue?macro=true";
import { default as indexu2o3BFzejeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/unsubscribe/index.vue?macro=true";
import { default as setupXDgMzbj45dMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup.vue?macro=true";
import { default as index3nqDxlTiofMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/team/members/index.vue?macro=true";
import { default as settingWGKBXdviXeMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting.vue?macro=true";
import { default as indexj3e2uNLEieMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/team/index.vue?macro=true";
import { default as indexsAdLC2hw37Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/edit/index.vue?macro=true";
import { default as index08Xlp1ZYYzMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/index.vue?macro=true";
import { default as indexc8A8evomzmMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/new/index.vue?macro=true";
import { default as indexAfolHj6b3rMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details/index.vue?macro=true";
import { default as detailsBexCAk225KMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details.vue?macro=true";
import { default as indexs2mLOAnKhQMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/new/index.vue?macro=true";
import { default as indexcFCncjThWKMeta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table/index.vue?macro=true";
import { default as tableADW8GFfqg5Meta } from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/404/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexR3xAZAkeKqMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jhoN2NLrrrMeta?.name,
    path: "/audience/contact/info/:id()",
    meta: _91id_93jhoN2NLrrrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-contact-info-id",
    path: "",
    meta: indexjSknToo52wMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-info-id-timeline",
    path: "timeline",
    meta: indexPuzbpY4jRTMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/info/[id]/timeline/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tablenkdHMUlYUSMeta?.name,
    path: "/audience/contact/table",
    meta: tablenkdHMUlYUSMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-contact-table-bounced",
    path: "bounced",
    meta: indexv6H2bugwoGMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/bounced/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table",
    path: "",
    meta: indexAGpwYeOwSPMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table-markedspam",
    path: "markedspam",
    meta: indextmxYjpa6WVMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/markedspam/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-contact-table-unsubscribed",
    path: "unsubscribed",
    meta: indexSBmf98pQEhMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/contact/table/unsubscribed/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93tM6NJq5ps4Meta?.name,
    path: "/audience/list/details/:id()",
    meta: _91id_93tM6NJq5ps4Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-details-id",
    path: "",
    meta: indexJawLiEgtn9Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/details/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-list-new",
    path: "/audience/list/new",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id",
    path: "import/:id()",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id].vue").then(m => m.default || m),
    children: [
  {
    name: match_45fieldsCQ9OxOMFjfMeta?.name,
    path: "match-fields",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id-match-fields",
    path: "",
    meta: index3evSX2wLBiMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/match-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: select_45fileYL30Wcd7CpMeta?.name,
    path: "select-file",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-new-import-id-select-file",
    path: "",
    meta: indexdi21XjllGhMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/new/import/[id]/select-file/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: tableMpXtWhOxkmMeta?.name,
    path: "/audience/list/table",
    meta: tableMpXtWhOxkmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-list-table",
    path: "",
    meta: indexO1jlh8wtmwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/list/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-segment-id-edit",
    path: "/audience/segment/:id()/edit",
    meta: index3ZCtaxs5u0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-segment-id",
    path: "/audience/segment/:id()",
    meta: indexj9g5L7YHl6Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "audience-segment-new",
    path: "/audience/segment/new",
    meta: indexnSmuNYPx7LMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/new/index.vue").then(m => m.default || m)
  },
  {
    name: tableasoI3vWX7cMeta?.name,
    path: "/audience/segment/table",
    meta: tableasoI3vWX7cMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-segment-table",
    path: "",
    meta: indexWnQN7wmnrrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/segment/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_933zTNYzdrZ7Meta?.name,
    path: "/audience/tag/details/:id()",
    meta: _91id_933zTNYzdrZ7Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-details-id",
    path: "",
    meta: indexBrnjqOPM7JMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/details/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "audience-tag-new",
    path: "/audience/tag/new",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id",
    path: "import/:id()",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id].vue").then(m => m.default || m),
    children: [
  {
    name: match_45fieldstOrfTMRquhMeta?.name,
    path: "match-fields",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id-match-fields",
    path: "",
    meta: indexNoCdVhavw5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/match-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: select_45fileTdo07y7InxMeta?.name,
    path: "select-file",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-new-import-id-select-file",
    path: "",
    meta: indexBOBIGVBoGkMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/new/import/[id]/select-file/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: table9Ngr8623HJMeta?.name,
    path: "/audience/tag/table",
    meta: table9Ngr8623HJMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table.vue").then(m => m.default || m),
    children: [
  {
    name: "audience-tag-table",
    path: "",
    meta: indexMkhGcCxqu5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/audience/tag/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-edit-id",
    path: "/automate/automations/edit/:id()",
    meta: _91id_9398HhicJj5EMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "automate-automations-edit-id-configure",
    path: "configure",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/configure/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-edit-id-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-edit-id-name",
    path: "name",
    meta: indexG8AC7KMJ2bMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/edit/[id]/name/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-info-id",
    path: "/automate/automations/info/:id()",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/info/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new",
    path: "/automate/automations/new",
    meta: new5ipGxHlT8xMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-automations-new-configure",
    path: "configure",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/configure/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new-create",
    path: "create",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/create/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-automations-new-name",
    path: "name",
    meta: indexZ0oNcutsZSMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/new/name/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-automations-table",
    path: "/automate/automations/table",
    meta: tableDzisSdk2xzMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/automations/table.vue").then(m => m.default || m)
  },
  {
    name: "automate-bulk-action-table",
    path: "/automate/bulk-action/table",
    meta: indexXtipGTxNDSMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/bulk-action/table/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id",
    path: "/automate/workflow/:id()",
    meta: _91id_93B1x9ANOq3TMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-id-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: overviewcij3A4nRgkMeta?.name,
    path: "email/:eid()/overview",
    meta: overviewcij3A4nRgkMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-id-email-eid-overview",
    path: "",
    meta: indexxGvy5rUaIsMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-email-eid-overview-performance",
    path: "performance",
    meta: indexNfFULWp8sPMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/email/[eid]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-workflow-id-goal",
    path: "goal",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/goal.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-report",
    path: "report",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/report.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-id-timeline",
    path: "timeline",
    meta: timelineIc1blVBGg3Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/[id]/timeline.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "automate-workflow-new",
    path: "/automate/workflow/new",
    meta: new3uwK7ZMf9jMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-new-edit",
    path: "edit",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-goal",
    path: "goal",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/goal/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-template",
    path: "template",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/template/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-new-timeline",
    path: "timeline",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/new/timeline/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableMznWCbNrNyMeta?.name,
    path: "/automate/workflow/table",
    meta: tableMznWCbNrNyMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table.vue").then(m => m.default || m),
    children: [
  {
    name: "automate-workflow-table-active",
    path: "active",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table-draft",
    path: "draft",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table",
    path: "",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/index.vue").then(m => m.default || m)
  },
  {
    name: "automate-workflow-table-paused",
    path: "paused",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/automate/workflow/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewKi5ybeu2GwMeta?.name,
    path: "/campaign/:id()/overview",
    meta: overviewKi5ybeu2GwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-id-overview",
    path: "",
    meta: indexI8CWImfjipMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-id-overview-performance",
    path: "performance",
    meta: indexEuD4t6Jj9DMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-id",
    path: "/campaign/abtest/:id()",
    meta: indexQ1crSfMJBuMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewQJwZg04d8OMeta?.name,
    path: "/campaign/abtest/:id()/overview",
    meta: overviewQJwZg04d8OMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-id-overview",
    path: "",
    meta: index8nmmasNJRyMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-id-overview-performance",
    path: "performance",
    meta: indexg4dyp3yao3Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-edit-id",
    path: "/campaign/abtest/edit/:id()",
    meta: _91id_93u8iDpR3Ub2Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-edit-id-content",
    path: "content",
    meta: indexyNPpaoOBlqMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-design",
    path: "design",
    meta: indexndA8ijzudmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-recipient",
    path: "recipient",
    meta: indexdF1dmVFGokMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-review",
    path: "review",
    meta: indexC5Qc5xjPp0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-schedule",
    path: "schedule",
    meta: indexTHz5YebS3dMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-edit-id-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-abtest-new",
    path: "/campaign/abtest/new",
    meta: newu2GaXjf1eUMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-new-content",
    path: "content",
    meta: indexhbXiylsp8WMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-design",
    path: "design",
    meta: indexBaFBxlEjVsMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-recipient",
    path: "recipient",
    meta: indexSMVg4YaoRhMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-review",
    path: "review",
    meta: index3ElI2UuLvPMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-schedule",
    path: "schedule",
    meta: indexz0aH9B674fMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-new-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tablevrvrbDFmxqMeta?.name,
    path: "/campaign/abtest/table",
    meta: tablevrvrbDFmxqMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-abtest-table-draft",
    path: "draft",
    meta: index1XXLZGoD6JMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-finished",
    path: "finished",
    meta: indexD5umTmMjcFMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/finished/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table",
    path: "",
    meta: indexIJz9qlpjj9Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-scheduled",
    path: "scheduled",
    meta: indexIFjn6zVbKcMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/scheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-tested",
    path: "tested",
    meta: indexkWv6mrGny8Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/tested/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-testing",
    path: "testing",
    meta: indexoS0sRR7qPZMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/testing/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-winnerScheduled",
    path: "winnerScheduled",
    meta: indexZzRcc1StHWMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerScheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-abtest-table-winnerSending",
    path: "winnerSending",
    meta: index892ijadtxXMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/abtest/table/winnerSending/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewChsl29o2rpMeta?.name,
    path: "/campaign/autotrigger/:id()/overview",
    meta: overviewChsl29o2rpMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-id-overview",
    path: "",
    meta: indexQcc7SM8jU0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-id-overview-performance",
    path: "performance",
    meta: indexGW7VNwmx2YMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-autotrigger-edit-id",
    path: "/campaign/autotrigger/edit/:id()",
    meta: _91id_93UjAI8FRLnwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-edit-id-content",
    path: "content",
    meta: indexpgXSog0rXNMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-design",
    path: "design",
    meta: indexrLn9QGJV8TMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-review",
    path: "review",
    meta: indexlLvkSPoFrrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-sender",
    path: "sender",
    meta: indextYmmv7155fMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-edit-id-trigger",
    path: "trigger",
    meta: indexcnISNFD9mwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/edit/[id]/trigger/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-autotrigger-new",
    path: "/campaign/autotrigger/new",
    meta: new2pc5sbE1zvMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-new-content",
    path: "content",
    meta: indexGsuwlpLGTDMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-design",
    path: "design",
    meta: indexauID4iAFkvMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-review",
    path: "review",
    meta: indexdbkTjHj6zWMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-sender",
    path: "sender",
    meta: index0t4BBEjWQPMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-new-trigger",
    path: "trigger",
    meta: index41e71ORNk3Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/new/trigger/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tablee4Dwl6vKpSMeta?.name,
    path: "/campaign/autotrigger/table",
    meta: tablee4Dwl6vKpSMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-autotrigger-table-active",
    path: "active",
    meta: indexoP4hKcv7JgMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-table-draft",
    path: "draft",
    meta: indexjIX9lwslITMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-autotrigger-table",
    path: "",
    meta: indexqqiCk4rBhfMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/autotrigger/table/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-id",
    path: "/campaign/drip/:id()",
    meta: indexPeDUZPVnbrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewHXaMhczTxuMeta?.name,
    path: "/campaign/drip/:id()/overview",
    meta: overviewHXaMhczTxuMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-id-overview",
    path: "",
    meta: indexkKMrBHqc9vMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-id-overview-performance",
    path: "performance",
    meta: indexzMYevtuceIMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-edit-id",
    path: "/campaign/drip/edit/:id()",
    meta: _91id_93j5A8rtPir2Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-edit-id-content",
    path: "content",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-edit-id-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-drip-new",
    path: "/campaign/drip/new",
    meta: newczplICRaMyMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-new-content",
    path: "content",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-new-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: table0GNyNSlIKsMeta?.name,
    path: "/campaign/drip/table",
    meta: table0GNyNSlIKsMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-drip-table-active",
    path: "active",
    meta: indexHGNHD2V9IhMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-table",
    path: "",
    meta: indexywtRqHd4jeMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-drip-table-paused",
    path: "paused",
    meta: indexIKDcXrvfhQMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/drip/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-newsletter-edit-id",
    path: "/campaign/newsletter/edit/:id()",
    meta: _91id_93oEaLCTH6lbMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-edit-id-content",
    path: "content",
    meta: indexAb851awbJWMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-design",
    path: "design",
    meta: index1UQBWpfY47Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-recipient",
    path: "recipient",
    meta: indexA6EFJnJGy5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-review",
    path: "review",
    meta: indexz7HL0J7bVMMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-schedule",
    path: "schedule",
    meta: indexSHuAD2VElGMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-edit-id-sender",
    path: "sender",
    meta: index3dHpzNJyVFMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/edit/[id]/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-newsletter-new",
    path: "/campaign/newsletter/new",
    meta: newpWqBhZLf8NMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-new-content",
    path: "content",
    meta: indexKfqpBc7zdCMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-design",
    path: "design",
    meta: indexcd6cqCM2pHMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-recipient",
    path: "recipient",
    meta: indextojMhdl7GvMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-review",
    path: "review",
    meta: indexGof661UY8IMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/review/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-schedule",
    path: "schedule",
    meta: indexlKw27qNNciMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-new-sender",
    path: "sender",
    meta: index4hNFOFhyzQMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/new/sender/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableQoLpTWL2DxMeta?.name,
    path: "/campaign/newsletter/table",
    meta: tableQoLpTWL2DxMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-newsletter-table-draft",
    path: "draft",
    meta: indexk4CfhgwbCqMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table",
    path: "",
    meta: indexah5ajdwTbwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-quarantined",
    path: "quarantined",
    meta: index225xdsNGNLMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/quarantined/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-scheduled",
    path: "scheduled",
    meta: indexnuTL1urV6qMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/scheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-sending",
    path: "sending",
    meta: index6K6lPWlwfpMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sending/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-newsletter-table-sent",
    path: "sent",
    meta: indexeF5orLlTtKMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/newsletter/table/sent/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-report",
    path: "/campaign/report",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/report/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-id",
    path: "/campaign/rss/:id()",
    meta: indexpqDrvxwxIjMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewNB5asYabdrMeta?.name,
    path: "/campaign/rss/:id()/overview",
    meta: overviewNB5asYabdrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-id-overview",
    path: "",
    meta: indexhzEhyf3vNUMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-id-overview-performance",
    path: "performance",
    meta: indexROUYvA6fjXMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/[id]/overview/performance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-rss-edit-id",
    path: "/campaign/rss/edit/:id()",
    meta: _91id_93Ay6PXBB98TMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-edit-id-basic",
    path: "basic",
    meta: indexE6XQAFlXP5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-content",
    path: "content",
    meta: indexkVKoJxyDLrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-recipient",
    path: "recipient",
    meta: indexvXazr30bCOMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-edit-id-schedule",
    path: "schedule",
    meta: indexdeqtp5ZPIEMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/edit/[id]/schedule/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "campaign-rss-new",
    path: "/campaign/rss/new",
    meta: newwXJyM1nAT1Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-new-basic",
    path: "basic",
    meta: indexLiAduCGwbrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-content",
    path: "content",
    meta: indexWvNfjGd7y0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-recipient",
    path: "recipient",
    meta: indexyvJ7xMv7D6Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/recipient/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-new-schedule",
    path: "schedule",
    meta: indexQxAZzp2XOrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/new/schedule/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tableKP3ORl0HTtMeta?.name,
    path: "/campaign/rss/table",
    meta: tableKP3ORl0HTtMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table.vue").then(m => m.default || m),
    children: [
  {
    name: "campaign-rss-table-active",
    path: "active",
    meta: indexzkkVvwuBT7Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/active/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table-draft",
    path: "draft",
    meta: indexlir66only9Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/draft/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table",
    path: "",
    meta: indexFLlvF8HPniMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-rss-table-paused",
    path: "paused",
    meta: indexN9m1DhMVQIMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/campaign/rss/table/paused/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexxn5IOLkEIxMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "download",
    path: "/download",
    meta: indexXftLBROX4OMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: overviewvKU6hiI1vjMeta?.name,
    path: "/form/embedded/:id()/overview",
    meta: overviewvKU6hiI1vjMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-embedded-id-overview",
    path: "",
    meta: indexw2HlcI15CIMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-instructions",
    path: "instructions",
    meta: indexhu8Aqg3KZcMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-performance",
    path: "performance",
    meta: index5KOeqsA7HZMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-id-overview-submission",
    path: "submission",
    meta: indexnXHsEZnLLgMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-embedded-edit-id",
    path: "/form/embedded/edit/:id()",
    meta: _91id_93GD6fEuPC7AMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-embedded-edit-id-basic",
    path: "basic",
    meta: index9vIXdPOj0iMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-content",
    path: "content",
    meta: indexrnY9GH540wMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-design",
    path: "design",
    meta: index6unOk28NDAMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-success",
    path: "success",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-embedded-edit-id-targeting",
    path: "targeting",
    meta: indexV0ibwN8MgKMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/embedded/edit/[id]/targeting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewhoZ7yWU9XRMeta?.name,
    path: "/form/landing-page/:id()/overview",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-landing-page-id-overview",
    path: "",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-instructions",
    path: "instructions",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-performance",
    path: "performance",
    meta: indexppFi8TJ7o1Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-id-overview-submission",
    path: "submission",
    meta: indexzRhz3RyfHHMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-landing-page-edit-id",
    path: "/form/landing-page/edit/:id()",
    meta: _91id_93EFQAZj5621Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-landing-page-edit-id-basic",
    path: "basic",
    meta: index2B6gmeK8jNMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-content",
    path: "content",
    meta: indexgjc14b0xYfMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-design",
    path: "design",
    meta: indexuO2J4k5j0IMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-success-page",
    path: "success-page",
    meta: index6gaqQUguYUMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-landing-page-edit-id-success",
    path: "success",
    meta: indexFJ7T2qBREWMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/landing-page/edit/[id]/success/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-new",
    path: "/form/new",
    meta: newnoTJy6aMGaMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new.vue").then(m => m.default || m),
    children: [
  {
    name: "form-new-basic-EmbeddedForm",
    path: "basic/EmbeddedForm",
    meta: EmbeddedFormIWAvLfULvdMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic",
    path: "basic",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic-LandingPage",
    path: "basic/LandingPage",
    meta: LandingPageduWCRDhmxZMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-basic-Popup",
    path: "basic/Popup",
    meta: PopupaWK6Z8TuB5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/basic/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-behaviour",
    path: "behaviour",
    meta: index2T8DNan26iMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/behaviour/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-EmbeddedForm",
    path: "content/EmbeddedForm",
    meta: EmbeddedFormvpVCDFa2YBMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-EmbeddedForm2",
    path: "content/EmbeddedForm2",
    meta: EmbeddedForm2gMC8LVJrDMMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/EmbeddedForm2.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content",
    path: "content",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-LandingPage",
    path: "content/LandingPage",
    meta: LandingPage9AaQdIw5gxMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-Popup",
    path: "content/Popup",
    meta: PopupCD8HDjUZj8Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-content-Popup2",
    path: "content/Popup2",
    meta: Popup2MrAiBeim12Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/content/Popup2.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-EmbeddedForm",
    path: "design/EmbeddedForm",
    meta: EmbeddedFormdor0K0Swx8Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design",
    path: "design",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-LandingPage",
    path: "design/LandingPage",
    meta: LandingPageInZnLue3ZlMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-design-Popup",
    path: "design/Popup",
    meta: PopupnBTHGThsS4Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/design/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-page",
    path: "success-page",
    meta: indexjOfPwFLEHeMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-EmbeddedForm",
    path: "success/EmbeddedForm",
    meta: EmbeddedFormtW4Gc318feMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success",
    path: "success",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-LandingPage",
    path: "success/LandingPage",
    meta: LandingPagedSvlUqtCPiMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/LandingPage.vue").then(m => m.default || m)
  },
  {
    name: "form-new-success-Popup",
    path: "success/Popup",
    meta: PopuphuKFZ8EXHYMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/success/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting-EmbeddedForm",
    path: "targeting/EmbeddedForm",
    meta: EmbeddedForm55ufzucMeZMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/EmbeddedForm.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting",
    path: "targeting",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/index.vue").then(m => m.default || m)
  },
  {
    name: "form-new-targeting-Popup",
    path: "targeting/Popup",
    meta: PopupuGVXCmEpD7Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/targeting/Popup.vue").then(m => m.default || m)
  },
  {
    name: "form-new-type",
    path: "type",
    meta: indexvQJYFtoDs0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/new/type/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: overviewBTUeWhiJCrMeta?.name,
    path: "/form/popup/:id()/overview",
    meta: overviewBTUeWhiJCrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview.vue").then(m => m.default || m),
    children: [
  {
    name: "form-popup-id-overview",
    path: "",
    meta: indexfuTwMWP80HMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-instructions",
    path: "instructions",
    meta: indexAQ9JzFdEbmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/instructions/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-performance",
    path: "performance",
    meta: indexJVI8rTDka8Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/performance/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-id-overview-submission",
    path: "submission",
    meta: index5eEPSvaVw4Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/[id]/overview/submission/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-popup-edit-id",
    path: "/form/popup/edit/:id()",
    meta: _91id_93CKX0rTTEeHMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "form-popup-edit-id-basic",
    path: "basic",
    meta: indexZq2TmwGSusMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/basic/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-behaviour",
    path: "behaviour",
    meta: indexzEyTy3XkZpMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/behaviour/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-content",
    path: "content",
    meta: indexkB4fxy6iuxMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/content/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-design",
    path: "design",
    meta: indexkvuPLbow3DMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/design/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-success",
    path: "success",
    meta: indexo3BpUVyxtaMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/success/index.vue").then(m => m.default || m)
  },
  {
    name: "form-popup-edit-id-targeting",
    path: "targeting",
    meta: indexlkWBofXliGMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/popup/edit/[id]/targeting/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "form-table",
    path: "/form/table",
    meta: table3uFWvvqAWRMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table.vue").then(m => m.default || m),
    children: [
  {
    name: "form-table-embed",
    path: "embed",
    meta: indexiGyy3Su4qYMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/embed/index.vue").then(m => m.default || m)
  },
  {
    name: "form-table-landing-page",
    path: "landing-page",
    meta: index5rrKVdAUxmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/landing-page/index.vue").then(m => m.default || m)
  },
  {
    name: "form-table-popup",
    path: "popup",
    meta: indexuadCLRSIunMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/form/table/popup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexNEUHO9Zk4zMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-click-funnels",
    path: "/integration/click-funnels",
    meta: indexAoEGmsCgStMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/click-funnels/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-convert-box",
    path: "/integration/convert-box",
    meta: index0XLexsik4ZMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/convert-box/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-demio",
    path: "/integration/demio",
    meta: indexS9vIRcexQlMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/demio/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-hubspot",
    path: "/integration/hubspot",
    meta: indexRTDQ3TIz7NMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/hubspot/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-instapage",
    path: "/integration/instapage",
    meta: indexN2r0f85x6uMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/instapage/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-intercom",
    path: "/integration/intercom",
    meta: indexou9lk2vLSyMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/intercom/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-paypal",
    path: "/integration/paypal",
    meta: indexpeVNlJ4reCMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/paypal/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-pipedrive",
    path: "/integration/pipedrive",
    meta: indexDWJQUUkzjNMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/pipedrive/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-samcart",
    path: "/integration/samcart",
    meta: indexBhQq4gz2mTMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/samcart/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-stripe",
    path: "/integration/stripe",
    meta: indextFZfQBzaIrMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/stripe/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-teachable",
    path: "/integration/teachable",
    meta: indexw1dyaOoPDmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/teachable/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-twilio",
    path: "/integration/twilio",
    meta: indexktFBXpAifyMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/twilio/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-woo-commerce",
    path: "/integration/woo-commerce",
    meta: indexHB8SvSLQs0Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woo-commerce/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-woorise",
    path: "/integration/woorise",
    meta: indexysR59ENHquMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/woorise/index.vue").then(m => m.default || m)
  },
  {
    name: "integration-zapier",
    path: "/integration/zapier",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/integration/zapier/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexroNVhdxkDJMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indextSf3AjXJ3TMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-member",
    path: "/onboarding/member",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/onboarding/member/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexBnslPupYKQMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexoOu7hCmGCaMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    meta: indexo0crg569oDMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword-newpassword",
    path: "/resetpassword/newpassword",
    meta: indexRGonIvKrTKMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/resetpassword/newpassword/index.vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-billing",
    path: "billing",
    meta: indexYcKxRh3mtNMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-connectors",
    path: "connectors",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-connectors-api",
    path: "api",
    meta: index0SJcp37lSVMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/api/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-connectors-webhook",
    path: "webhook",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/connectors/webhook/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-custom-properties",
    path: "custom-properties",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-custom-properties-custom-events-id",
    path: "custom-events/:id()",
    meta: _91id_933cQuULhnUYMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/[id].vue").then(m => m.default || m)
  },
  {
    name: "setting-custom-properties-custom-events",
    path: "custom-events",
    meta: indexvX6vOQd0DSMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-events/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-custom-properties-custom-fields",
    path: "custom-fields",
    meta: indexXk9ED46CiwMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/custom-properties/custom-fields/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-home-page",
    path: "home-page",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/home-page/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace",
    path: "marketplace",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-marketplace-add-ons",
    path: "add-ons",
    meta: indexQoMXrGEv5AMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/add-ons/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace-integrations",
    path: "integrations",
    meta: indexgReD911T6pMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-marketplace-partners",
    path: "partners",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/marketplace/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-setup",
    path: "setup",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup.vue").then(m => m.default || m),
    children: [
  {
    name: "setting-setup-company-information",
    path: "company-information",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/company-information/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-domain-whitelisting",
    path: "domain-whitelisting",
    meta: indexA5nULu21GCMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/domain-whitelisting/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-editor",
    path: "editor",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/editor/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-sender",
    path: "sender",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/sender/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-template",
    path: "template",
    meta: index0RXe2HjZZLMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/template/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-setup-unsubscribe",
    path: "unsubscribe",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/setup/unsubscribe/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "setting-team-members",
    path: "team/members",
    meta: index3nqDxlTiofMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/setting/team/members/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: "template-id-edit",
    path: "/template/:id()/edit",
    meta: indexsAdLC2hw37Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "template-id",
    path: "/template/:id()",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "template-new",
    path: "/template/new",
    meta: indexc8A8evomzmMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/template/new/index.vue").then(m => m.default || m)
  },
  {
    name: detailsBexCAk225KMeta?.name,
    path: "/tickets/:id()/details",
    meta: detailsBexCAk225KMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details.vue").then(m => m.default || m),
    children: [
  {
    name: "tickets-id-details",
    path: "",
    meta: indexAfolHj6b3rMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/[id]/details/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tickets-new",
    path: "/tickets/new",
    meta: indexs2mLOAnKhQMeta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/new/index.vue").then(m => m.default || m)
  },
  {
    name: tableADW8GFfqg5Meta?.name,
    path: "/tickets/table",
    meta: tableADW8GFfqg5Meta || {},
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table.vue").then(m => m.default || m),
    children: [
  {
    name: "tickets-table",
    path: "",
    component: () => import("/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/pages/tickets/table/index.vue").then(m => m.default || m)
  }
]
  }
]